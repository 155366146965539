import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface ConfigState {
  technologyModal: boolean,
}

const initialState: ConfigState = {
  technologyModal: false,
};


export const configSlice = createSlice({
  name: 'appConfig',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTechnologyModal: (state, action: PayloadAction<boolean>) => {
      state.technologyModal = action.payload;
    },
  },
});

export const { setTechnologyModal } = configSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const isTechnologyModalOpen = (state: RootState) => state.appConfig.technologyModal;

export default configSlice.reducer;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Progress,
  Box,
  Stack,
  Container,
  Text,
} from "@chakra-ui/react";
import {
  isTechnologyModalOpen,
  setTechnologyModal,
} from "../features/config/configSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";

const experiencedTechnology = [
  {
    tech: "HTML",
    level: 8,
  },
  {
    tech: "CSS",
    level: 8,
  },
  {
    tech: "JavaScript",
    level: 8,
  },
  {
    tech: "ReactJs",
    level: 9,
  },
  {
    tech: "Redux",
    level: 9,
  },
  {
    tech: "Typescript",
    level: 8,
  },
  {
    tech: "Bootstrap",
    level: 8,
  },
  {
    tech: "Mui react",
    level: 8,
  },
  {
    tech: "ExpressJs",
    level: 5,
  },
  {
    tech: "NodeJS",
    level: 5,
  },
];

export default function TechnologyModal() {
  const isOpen = useAppSelector(isTechnologyModalOpen);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setTechnologyModal(false));
  };

  return (
    <>
      <Modal isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Technology Experience</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"} gap={"10px"} pb={6}>
            {experiencedTechnology.map((technology) => (
              <Stack direction={["column", "row"]}>
                <Box w="30%">
                  <Text color={"gray.600"} fontSize="xl" fontWeight={"bold"} fontFamily={"monospace"}>
                    {technology.tech}
                  </Text>
                </Box>
                <Box
                  as={Container}
                  alignItems="start"
                  textAlign="initial"
                  maxW="full"
                  m="auto"
                >
                  <Progress size="xs" colorScheme="teal" max={10} hasStripe value={technology.level} />
                </Box>
              </Stack>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter your name")
    .min(3, 'The field "name" must be at least 3 chars'),
  email: Yup.string()
    .required("Please enter your Email")
    .email("Email is invalid"),
  comment: Yup.string()
    .required("Please enter your message")
    .min(6, 'The field "Message" must be at least 10 chars')
    .max(300, 'The field "Message" must not exceed 20 characters'),
});

import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Contact from "./pages/contactPage/Contact";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/homePage/Home";
import About from "./pages/aboutPage/About";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Stack,
  useColorModeValue,
  CardBody,
  Card,
  CardHeader,
  StackDivider,
  Image,
} from "@chakra-ui/react";
import features from "../../constants/experience";
import profile from "../../images/khalid.jpeg";

export default function Home() {
  return (
    <Container
      bg={useColorModeValue("gray.100", "gray.700")}
      maxW="full"
      minH="calc(100vh - 125px)"
      mt={0}
      overflow="hidden"
    >
      <Box p={4}>
        <Stack direction={["column", "row"]} py={4}>
          <Image
            boxSize="150px"
            objectFit="cover"
            width={[
              "100%", // 0-30em
              "50%", // 30em-48em
              "50%", // 48em-62em
              "15%", // 62em+
            ]}
            src={profile}
            alt="mohammed khalid"
          />
          <Box
            as={Container}
            alignItems="start"
            textAlign="initial"
            maxW="full"
          >
            <Heading fontSize={"3xl"} mb={4}>
              I'm Mohammed Khalid
            </Heading>
            <Text color={"gray.600"} fontSize={"xl"}>
              Innovative Front-End Developer with 8+ years of experience
              building and maintaining responsive web projects in several
              industries like banking, entertainment, communication, and travel
              industry. I am a strong engineering professional skilled in HTML,
              CSS, JavaScript, plus modern libraries, and frameworks. Hands-on
              experience in DevOps tools like Docker and Kubernetes.
            </Text>
          </Box>
        </Stack>
        <Stack p={6} textAlign={"center"} bg="gray.200">
          <Heading fontSize={"3xl"}>Projects</Heading>
        </Stack>

        <Container maxW={"9xl"} mt={10}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            {features.map((feature, index) => (
              <Card key={index.toString()}>
                <CardHeader>
                  <Heading size="md">{feature.title}</Heading>
                </CardHeader>

                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Summary
                      </Heading>
                      <Text pt="2" fontSize="sm" color={"gray.600"}>
                        {feature.summary}
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Technologies
                      </Heading>
                      <Text pt="2" fontSize="sm" color={"gray.600"}>
                        Check out the overview of your clients.
                      </Text>
                    </Box>
                    <Box>
                      <Heading size="xs" textTransform="uppercase">
                        Responsibility
                      </Heading>
                      <Text pt="2" fontSize="sm" color={"gray.600"}>
                        See a detailed analysis of all your business clients.
                      </Text>
                    </Box>
                  </Stack>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </Container>
      </Box>
    </Container>
  );
}

const features = [
  {
    title: "Convera - Payment Dashboard",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Risk Detect Lending",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "SpaceWarp",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Imageware - LE (Law Enforcement)",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Data Sync Admin UI (Autozone)",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Myca (https://www.myca.ai/)",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Dollar Shave Club (https://www.dollarshaveclub.com/)",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "NBA",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Retail Management System (T-Mobile)",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Ticketmaster",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Apigee Training App",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
  },
  {
    title: "Safetix Config Tool",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
  },
  {
    title: "AML- Customer Due Diligence (CDD)",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
  },
  {
    title: "Marketplace",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
  },
  {
    title: "Travelport Rail Service",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
  },
  {
    title: "KingsTrack (https://mvt.thegoldenelement.com/)",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
  },
  {
    title:
      "ArmorOn (https://play.google.com/store/apps/details?id=com.armoron10.apmkingstrack)",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
  },
];

export default features;
